/* eslint-disable no-unused-vars */
const local = {
  API_BASE_URL: "http://192.168.1.101:8585/api/v1",
  SPACE_URL: "https://leadias.sgp1.digitaloceanspaces.com/",
  GOOGLE_CLIENT_ID:
    "607907899391-3qcn9qfdabbso0bt68ugq5jbmri724gs.apps.googleusercontent.com",
};

const dev = {
  API_BASE_URL: "https://dev.las.edstem.com/api/v1",
  SPACE_URL: "https://leadias.sgp1.digitaloceanspaces.com/",
  GOOGLE_CLIENT_ID:
    "607907899391-3qcn9qfdabbso0bt68ugq5jbmri724gs.apps.googleusercontent.com",
};

const stage = {
  API_BASE_URL: "https://dev.api.leadiasacademy.com/api/v1",
  SPACE_URL: "https://leadias.sgp1.digitaloceanspaces.com/",
  GOOGLE_CLIENT_ID:
    "607907899391-3qcn9qfdabbso0bt68ugq5jbmri724gs.apps.googleusercontent.com",
};

// change dev to local to point to local server.
const config = process.env.REACT_APP_STAGE === "stage" ? stage : local;

export default {
  ...config,
};
